import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const outerStyle = {
  // TODO: Share with AuthorityBadge?
  lineHeight: 1,
  whiteSpace: "nowrap",
  display: "inline-flex",
  verticalAlign: "middle",
  marginTop: "-0.4em",
};

const baseStyles = {
  tooltip: {
    ...outerStyle,
  },
  noTooltip: {
    ...outerStyle,
  },
  claimed: {
    background: gStyles.claimedGradient(324),
    marginLeft: "0.5rem",
    borderRadius: "5em",
    display: "inline-flex",
    alignItems: "center",
    color: "#fff",
    flexDirection: "row",
    fontSize: "1em",
    height: "1em",
  },
  claimedIcon: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    lineHeight: "0.8em",
    justifyContent: "center",
    borderRadius: "5em",
    fontSize: "0.75em",
  },
  claimedText: {
    ...gStyles.avalonBold,
    marginLeft: "0.5em",
    display: "inline-block",

    lineHeight: "0.8em",
    marginTop: "0.1em",
    fontSize: "0.75em",
  },
};

const paddedStyles = {
  claimedIcon: {
    fontSize: "0.6125em",
  },
  claimedText: {
    fontSize: "0.6125em",
  },
};

const noLabelStyles = {
  claimed: {
    width: "1em",
    height: "1em",
  },
};

const labelStyles = {
  claimed: {
    paddingLeft: "0.4em",
    paddingRight: "0.4em",
  },
};

const tooltippedStyles = {
  tooltip: {
    marginLeft: "0.5rem",
  },
  claimed: {
    marginLeft: 0,
  },
};

const tooltipStyles = {
  tooltip: {
    zIndex: 9999,
  },
};

const ClaimedBadge = (props) => {
  const { showLabel, label, padded, tooltipText, showTooltip, size } = props;
  const { styles } = useStyles(
    [
      baseStyles,
      showTooltip && tooltipText && tooltippedStyles,
      padded && paddedStyles,
      !(showLabel && label) && noLabelStyles,
      showLabel && label && labelStyles,
      size && {
        tooltip: {
          marginTop: -Math.round(0.2 * size),
        },
        noTooltip: {
          marginTop: -Math.round(0.2 * size),
        },
        claimed: {
          width: showLabel && label ? "auto" : size,
          height: size,
          fontSize: `${Math.round(size * 100) / 100}px`,
        },
      },
    ],
    props
  );

  const badge = (
    <span className={css(styles.claimed)}>
      <span className={css(styles.claimedIcon)}>
        <FontAwesomeIcon icon={faCheck} />
      </span>
      {showLabel && label && (
        <span className={css(styles.claimedText)}>{label}</span>
      )}
    </span>
  );

  return showTooltip && tooltipText ? (
    <BasicTooltip renderTooltip={() => tooltipText} styles={tooltipStyles}>
      {(tooltipProps) => (
        <span {...tooltipProps} className={css(styles.tooltip)}>
          {badge}
        </span>
      )}
    </BasicTooltip>
  ) : (
    <span className={css(styles.noTooltip)}>{badge}</span>
  );
};

ClaimedBadge.propTypes = {
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  padded: PropTypes.bool,
  size: PropTypes.number,
};

ClaimedBadge.defaultProps = {
  label: "Claimed",
  showLabel: false,
  showTooltip: false,
  tooltipText: "Claimed on Podchaser",
  padded: true,
  size: undefined,
};

export default memo(ClaimedBadge);
